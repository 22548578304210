@mixin gdpr-container-mixin {
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
  }
  .links-container {
    text-align: center;
    margin-left: 1rem;
  }
}

.privacy-info-container {
  text-align: center;
  font-size: 0.8rem;
  color: $gray-600;

  a {
    color: $gray-600 !important;
    text-decoration: underline !important;
    &:hover {
      color: $gray-800 !important;
    }
  }

  img#gdpr-compliant {
    width: 125px;
    height: 50px;
    margin-bottom: 0.5rem;
  }

  &.force-horizontal {
    .gdpr-container {
      @include gdpr-container-mixin();
    }
    img#gdpr-compliant {
      margin-bottom: 0rem;
    }
  }
}

.mobile-container {
  img#gdpr-compliant {
    width: 100px;
    height: 40px;
  }

  .gdpr-container {
    margin-top: 30px;
    @include gdpr-container-mixin();
  }
}


@media print {
  .privacy-info-container {
    display: none;
  }
}
