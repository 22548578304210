body {
  background: $gzn-background;
  color: $gzn-text;
  font-size: 16px;
}

.desktop-container {
  $sidebar-width: 250px;
  padding-bottom: $gzn-padding;

  .step-footer-content {
    .btn {
      vertical-align: baseline;
    }

    .spinner {
      font-size: 150%;
      opacity: 0.8;
    }
  }

  .desktop-main-container {
    display: flex;

    .desktop-sidebar {
      vertical-align: top;
      flex: 0 1 auto;
      width: 30%;
      min-width: $sidebar-width;
      max-width: $sidebar-width;
    }

    .desktop-step-container {
      flex: 1 0 auto;
      width: 70%;
      padding-left: $gzn-padding;

      .step-content {
        border-radius: $border-radius;
        border: 8px solid $gzn-green;
        padding: $gzn-padding*2.5 $gzn-padding*3;
        background: #fff;
        min-height: 350px;
      }
      .step-footer {
        padding-top: $gzn-padding;
        text-align: right;
      }
    }
  }
}

.desktop-header {
  $sidebar-width: 250px;

  padding-bottom: $gzn-padding;
  padding-top: $gzn-padding;
  margin-bottom: $gzn-padding;
  border-bottom: 1px solid $gzn-border;
  background: #ffffff;

  .container {
    display: flex;
    align-items: flex-end;
    width: 100%;

    .sidebar-filler {
      width: $sidebar-width;
      margin-right: $gzn-padding;
    }

    .logo-container {
      margin-right: $gzn-padding;
      flex: 0 1 auto;
      padding-bottom: 6px;
      align-self: center;
      img {
        margin: auto;
        float: left;
        max-width: 100%;
        max-height: 45px;
      }
    }
    .name-container {
      flex: 1;
      align-self: center;
      min-width: 1rem;

      h1 {
        font-family: PT Serif;
        font-size: 1.6rem;
      }
      h1, p {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .contact-container {
      text-align: right;
      flex: 0 1 auto;
      align-self: center;
    }
    .language-selector {
      flex: 0 1 auto;
      margin-left: $gzn-padding;
      align-self: center;
    }

    .print-button-container {
      flex: 0 1 auto;
      margin-left: calc($gzn-padding/2);
      align-self: center;
    }
  }
}
