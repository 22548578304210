.piano-type-input {
  .option {
    border: 5px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    position: relative;
    opacity: 0.5;
    cursor: pointer;
    margin-bottom: 15px;

    &.active {
      border-color: $gzn-green;
      opacity: 1;
    }

    .check {
      display: block;
      width: 42px;
      font-size: 42px;
      position: absolute;
      top: -15px;
      left: -15px;

      .fa-circle {
        color: #fff;
      }
      .fa-check-circle {
        font-size: 36px;
        color: $gzn-green;
      }
    }
  }
}
