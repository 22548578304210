.overflowing-footer {
  background-color: $gzn-light-background;
  align-items: center;
  margin: calc($gzn-padding*-3);
  margin-bottom: calc($gzn-padding*-2.5);
  margin-top: 0;
  padding: calc($gzn-padding*2) calc($gzn-padding*3);
}
.modal-body {
  .overflowing-footer {
    @include border-bottom-radius($border-radius);
    padding: $modal-inner-padding;
    padding-top: calc($modal-inner-padding / 1.5);
    padding-bottom: calc($modal-inner-padding / 1.5);
    margin: calc(-1 * $modal-inner-padding);
    margin-top: calc($modal-inner-padding / 1.5);
    @include media-breakpoint-down(sm) {
      padding-left: $gzn-padding;
      padding-right: $gzn-padding;
      margin: calc(-1 * ($modal-inner-padding / 2));
      margin-top: calc($modal-inner-padding / 2);
    }
  }
}
