.desktop-container .step-content {
  h4 {
    font-family: PT Serif;
    font-size: 1.6rem;
  }

  h5 {
    font-family: PT Serif;
    font-size: 1.2rem;
  }
}

.mobile-container {
  font-size: 90%;

  .step-content {
    h4 {
      margin-left: -2rem;
      margin-right: -2rem;
      font-family: PT Serif;
      font-size: 1.45rem;
      text-align: center;
    }

    h5 {
      font-family: PT Serif;
      font-size: 1.1rem;
    }

  }
}
