.step6-finished-container {
  text-align: center;

  .message {
    max-width: 500px;
    margin: auto;
  }

  .appointment {
    display: flex;
    margin: auto;
    margin-top: 3rem;
    border: 1px solid $gzn-border;
    border-radius: $border-radius;
    padding: 1.5rem;
    max-width: 400px;

    .left-icon {
      flex: 0 1 auto;
      font-size: 48px;
      color: $gzn-green;
    }
    .right-content {
      padding-left: 2rem;
      text-align: left;
      flex: 1 0 auto;
      width: 99%;

      table td:first-child {
        text-align: right;
        padding-right: 0.5rem;
      }
      table td:last-child {
        font-weight: bold;
      }
    }

  }
}

.mobile-container {
  .appointment {
    display: block;
  }
}