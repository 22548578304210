
.form-group-with-errors, .generic-errors {
  color: $gzn-alert-red;

  .form-control {
    border-color: $gzn-alert-red;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($gzn-alert-red, 0.25);
    }
  }

  .form-errors {

    .form-error-message {
      display: flex;
      line-height: 1rem;
      margin-top: 0.25rem;

      svg {
        margin-right: 0.25rem;
      }
    }
  }
}

.generic-errors {
  margin-bottom: 1rem;

  .form-error-message {
    font-size: 100% !important;
    line-height: 1.4rem !important;

    svg {
      margin-right: 0.5rem !important;
    }
  }
}

.form-group-with-errors {
  .form-error-message {
    font-size: 80% !important;
  }
}
