.small-error-message {
  text-align: center;
  color: $gzn-alert-red;
  margin: auto;
  max-width: 600px;
  padding: 0 30px;

  .fa-exclamation-triangle {
    font-size: 32px;
    margin-bottom: 1rem;
  }

  a {
    color: $gzn-alert-red !important;
    text-decoration: underline;
  }

  .message-text {
    font-size: 16pt;
  }
}
