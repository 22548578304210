.duration-container {
  display: table;

  .left-side {
    display: table-cell;
    font-size: 32px;
    vertical-align: middle;
    padding-right: 15px;
  }
  .right-side {
    display: table-cell;
    vertical-align: middle;
  }
}

@include media-breakpoint-down(sm) {
  .modal-body {
    .duration-container {
      margin: auto;
    }

    .footer-column {
      text-align: center !important;
    }
  }
}