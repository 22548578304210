$datepicker__background-color: $gzn-light-background !default;
$datepicker__border-color: $gzn-light-border !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: $gzn-light-border !default;
$datepicker__selected-color: $gzn-green !default;
$datepicker__border-radius: $border-radius !default;
$datepicker__day-margin: 3px !default;
$datepicker__font-size: 1rem !default;
$datepicker__item-size: 2.2rem !default;
$datepicker__margin: .5rem !default;
$datepicker__navigation-size: .5rem !default;
$datepicker__triangle-size: 12px !default;

@import "~react-datepicker/src/stylesheets/datepicker";

.react-datepicker {
  font-family: inherit;
  border-width: 5px;
  border-color: #cfcfcf;
}
.react-datepicker__header {
  border-radius: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #cfcfcf;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2rem;
  margin: 2px;
}

