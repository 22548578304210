.navigation-steps {
  a {
    color: $gzn-text;
    text-decoration: none;
  }

  .step-wrapper {
    clear: both;
    background: #fff;
    border-radius: $border-radius;
    border-top-left-radius: 0;
    margin-bottom: 10px;
    margin-left: 15px;
    padding-left: 20px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    z-index: 98;

    &.active:before {
      // position: absolute;
      // content: ' ';
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      // border: 1px solid #bbb;
      // border-radius: $border-radius;
      // border-top-left-radius: 0;
      // z-index: 99;
    }

    .arrow {
      display: none;
    }
    &.active .arrow {
      position: absolute;
      right: -1.2 * $gzn-padding;
      top: calc(50% - 25px);
      color: $gzn-green;
      font-size: 35px;
      display: block;
    }

    .number {
      background: $gzn-green;
      position: absolute;
      top: 0;
      left: -15px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      z-index: 100;
    }

    .label {
      position: relative;
      top: 0;
      line-height: 30px;
      font-weight: bold;
    }

    .content {
      padding-top: 5px;
      padding-bottom: 10px;

      .change-link {
        color: #bbb;
        text-decoration: underline;
        font-size: 14px;
        margin-top: 5px;
      }
    }

    &.disabled {
      color: #bbb;
      cursor: default;
      .number {
        background: #afafaf;
      }
    }
  }
}

.mobile-navigation {
  .header {
    padding: 10px;
    border-bottom: 1px solid $gzn-light-border;
  }

  .navigation-steps {
    .step-wrapper {
      padding: 5px;
      padding-left: 40px;
      margin: 0;
      border-bottom: 1px solid $gzn-light-border;
      border-radius: 0px;

      .number {
        left: 5px;
        top: 5px;
      }
    }

    .arrow {
      display: none;
    }
  }
}