.street-address-with-map {
  .icon-container {
    float: left;
    margin-top: 1rem;
    height: 100%;
    padding-right: $gzn-padding;
    text-align: center;
    color: $gzn-alert-yellow;
    font-size: 42px;
  }
  .address-details {
    margin-top: 1rem;
  }
}