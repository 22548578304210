.mobile-container {
  $main-header-height: 85px;
  $step-footer-height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: #fff;

  h4 {
    font-size: 22px;
  }

  .step-footer-content {
    .btn {
      vertical-align: baseline;
    }

    .spinner {
      font-size: 150%;
      opacity: 0.8;
      color: #fff;
    }
  }

  .mobile-header {
    position: fixed;
    display: flex;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: $gzn-padding;
    z-index: 100;
    height: $main-header-height;
    align-items: center;
    border-bottom: 10px solid $gzn-green;

    .nav-toggle-container {
      flex: 0 1 auto;
      padding-right: $gzn-padding;
    }

    .logo-container {
      flex: 0 1 auto;
      margin-right: calc($gzn-padding/3);
      padding-bottom: 6px;
      img {
        margin: auto;
        float: left;
        max-width: 100%;
        max-height: 45px;
      }
    }
    .name-container {
      flex: 1;
      min-width: 1rem;
      h1 {
        font-family: PT Serif;
        font-size: 18px;
      }
      h1, p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
      }
    }
    .subheader-container {
      color: $text-muted;
    }
    .contact-container {
      text-align: right;
      flex: 0 1 auto;
    }

    .language-selector {
      flex: 0 1 auto;
      margin-left: calc($gzn-padding/3);
      align-self: center;

      svg {
        font-size: 1.4rem;
      }
    }

    .print-button-container {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1.65rem;
      .btn {
        font-size: 135%;
      }
    }
  }

  .mobile-content {
    margin-top: $main-header-height;
  }

  .step-footer {
    position: fixed;
    width: 100%;
    display: table;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    height: $step-footer-height;
    background: $gzn-green;
    vertical-align: middle;

    .btn-primary, .btn-outline-primary {
      background-color: #fff;
      border-color: #fff;
      color: $gray-900;
    }
    .btn-outline-primary {
      border-color: #fff !important;
      opacity: 0.8;
      background-color: transparent !important;
      color: #fff !important;
    }

    .step-footer-content {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
      text-align: right;
      padding-left: $gzn-padding;
      padding-right: $gzn-padding;
    }
  }

  .step-content {
    margin-top: $main-header-height;
    margin-bottom: $step-footer-height;
    padding: calc($gzn-padding * 2);
    background: #fff;

    .mobile-contact-container {
      text-align: center;
      margin-top: -1rem;
      margin-bottom: 1rem;
    }
  }

  .mobile-blocker {
    z-index: 200;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: rgba(0,0,0,0.65);
    transition: 150ms;

    &.visible {
      visibility: visible;
      opacity: 1;
      transition: 250ms;
    }
  }

  .mobile-navigation {
    z-index: 210;
    border-right: 1px solid $border-color;
    position: fixed;
    top: 0;
    left: -80%;
    bottom: 0;
    width: 80%;
    background: #fff;
    transition: 100ms;

    .header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .navigation-steps {
      position: absolute;
      top: 45px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: scroll;
    }

    &.visible {
      left: 0;
      transition: 250ms;
    }
  }
}

@media print {
  .mobile-container {
    position: static;

    .mobile-header {
      display: none;
    }
  }
}
