.large-error-message {
  text-align: center;
  margin: auto;
  max-width: 540px;

  .error-icon {
    font-size: 2.5rem;
    color: $gzn-alert-yellow;
  }

  h4 {

  }
}