.gdpr-compliance-container {
  margin-top: 1rem;
  text-align: center;
}

.gdpr-compliance {
  margin: auto;
  margin-top: 1rem;
  max-width: 600px;
  padding: 2rem;
  background: #fff;
  border: 5px solid $gzn-green;
  border-radius: 5px;

  .header {
    text-align: center;
    border-bottom: 1px solid $border-color;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .content {
    text-align: left;
  }
}

.mobile-container {
  .gdpr-compliance {
    border-width: 0;
  }
}