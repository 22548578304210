.error-container {
  text-align: center;
  max-width: 600px;
  font-size: 18px;
  margin-top: 50px;

  .error-icon {
    color: $gzn-alert-red;
    font-size: 2.2rem;
  }

  h1 {
    font-size: 1.4rem;
    margin-bottom: 1em;
  }
}
