.context-help-text {
  display: inline-block;
  padding: 2px 4px;
  margin: 0 1rem;
  text-decoration: underline dotted;
  text-transform: uppercase;
  font-size: 0.7rem;
  color: $gray-600;
  cursor: help;
  white-space: nowrap;

  &.narrow {
    margin: 0 0.25rem;
  }

  &.no-padding {
    margin: 0;
    padding: 0;
  }
}
