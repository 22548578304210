// Bootstrap Overrides
$gray-100: #f8f8f8 !default;
$gray-200: #e9e9e9 !default;
$gray-300: #dedede !default;
$gray-400: #cecece !default;
$gray-500: #adadad !default;
$gray-600: #868686 !default;
$gray-700: #494949 !default;
$gray-800: #343434 !default;
$gray-900: #212121 !default;

$modal-inner-padding: 3rem;
$line-height-base: 1.3;

$input-placeholder-color: $gray-400;
$min-contrast-ratio:   2.5 !default;

// Bootstrap Customizations
$primary: $gzn-green;
$secondary: $gray-600;
$success: $gzn-alert-green;
$danger: $gzn-alert-red;
$warning: $gzn-alert-yellow;
$info: $gzn-alert-blue;
$light: $gray-100;
$dark: $gray-800;

.text-color-green { color: $gzn-green; }
.text-color-blue { color: $gzn-blue; }
.text-color-cyan { color: $gzn-cyan; }
.text-color-orange { color: $gzn-orange; }
.text-color-yellow { color: $gzn-yellow; }
.text-color-purple { color: $gzn-purple; }
.text-color-gray { color: $gzn-gray; }
.text-color-alert-green { color: $gzn-alert-green; }
.text-color-alert-yellow { color: $gzn-alert-yellow; }
.text-color-alert-red { color: $gzn-alert-red; }

.text-small { font-size: 80%; }
.text-xs { font-size: 60%; }

@import "~bootstrap/scss/bootstrap";

.modal-footer {
  padding: calc($modal-inner-padding/2) $modal-inner-padding;
  border-top: 0;
  background-color: $gzn-light-background;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
.modal-sm {
  .modal-body {
    padding: calc($modal-inner-padding/2);
  }
  .modal-footer {
    padding: calc($modal-inner-padding/3) calc($modal-inner-padding/2);
  }
}
.modal-content.modal-overflow-hidden {
  overflow: hidden;
}

.popover {
  border-width: 5px;
  border-color: #cfcfcf;
  box-shadow: 0 0 40px rgba(0,0,0,0.75);
  .arrow {
    &:before {
      box-shadow: none;
      border: 0;
    }
  }
}
.bs-popover-bottom .arrow:after {
  top: -2px;
  border-bottom-color: #cfcfcf;
}
.bs-popover-top .arrow:after {
  bottom: -2px;
  border-top-color: #cfcfcf;
}
.bs-popover-left .arrow:after {
  right: -2px;
  border-left-color: #cfcfcf;
}
.bs-popover-right .arrow:after {
  left: -2px;
  border-right-color: #cfcfcf;
}

@include media-breakpoint-down(sm) {
  .modal-body {
    padding: calc($modal-inner-padding/2);
  }
  .modal-footer {
    padding: calc($modal-inner-padding/3) calc($modal-inner-padding/2);
  }
}

label.form-control-label {
  font-size: 80%;
  line-height: 1rem;
  margin-bottom: 0.25rem;
}

input.form-control, select.form-control, textarea.form-control,
input.form-control:focus, select.form-control:focus, textarea.form-control:focus {
  font-weight: bold;
  color: #212121;
}

.form-control::-webkit-input-placeholder {
  font-weight: normal;
}

.form-control::-moz-placeholder {
  font-weight: normal;
}

.form-control:-ms-input-placeholder {
  font-weight: normal;
}

.form-control::-ms-input-placeholder {
  font-weight: normal;
}

.form-control::placeholder {
  font-weight: normal;
}

.rbt-input-hint {
  font-weight: bold !important;
  width: 100%;
  opacity: 0.5;
}
