.loading-container {
  text-align: center;
  opacity: 0.5;
  padding: 50px 0;

  .spinner {
    font-size: 24px;
  }

  &.without-padding {
    padding: 0;
  }
}