// Gazelle Variables
$gzn-text: #212121; // Same as $gray-900 defined in styles/bootstrap.scssZ
$gzn-padding: 1rem;
$gzn-border: rgba(0,0,0,0.20);
$gzn-light-border: rgba(0,0,0,0.15);
$gzn-background: #dfdfdf;
$gzn-light-background: #efefef;
$gzn-green: #659933;
$gzn-orange: #AA5639;
$gzn-yellow: #AA9739;
$gzn-blue: #255E69;
$gzn-purple: #7A296B;
$gzn-cyan: #38a893;
$gzn-gray: #868686;  // Same as $gray-600 defined in styles/bootstrap.scss

$gzn-alert-green: $gzn-green;
$gzn-alert-red: #B03A3A;
$gzn-alert-yellow: #E9AD48;
$gzn-alert-blue: $gzn-cyan;

@import './styles/bootstrap';
@import './styles/elements';
@import './styles/datepicker';
@import './styles/errors';
@import './styles/loading_indicator';
@import './styles/overflowing_footer';
@import './styles/sticky_note';
@import './styles/simple_format';
@import './styles/large_error_message';
@import './styles/language_selector';
@import './styles/error_boundary';
@import './styles/error_container';
@import './styles/desktop_client_layout';
@import './styles/mobile_client_layout';
@import './styles/street_address_with_map';
@import "./styles/privacy";
@import "./styles/condition_report_graphic";
@import "./styles/small_error_message";

@import "../shared/styles";
