label.user-selector {
  border-radius: $border-radius;
  border: 1px solid $gzn-light-border;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    flex: 0 1 auto;
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  span {
    flex: 1 0 auto;
  }

  &.disabled {
    color: $text-muted;
  }

  &.any {
    border-width: 0;
  }
}