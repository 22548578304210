.error-boundary {
  border: 5px solid $gzn-alert-red;
  border-radius: 5px;
  background: #fff;
  padding: 3rem 0;

  .error-boundary-wrapper {
    margin: auto;
    text-align: center;
    max-width: 600px;
    font-size: 18px;
  }

  .error-icon {
    color: $gzn-alert-red;
    font-size: 2.2rem;
  }

  h1 {
    color: $gzn-alert-red;
    font-family: PT Serif;
    font-size: 1.4rem;
    margin-bottom: 1em;
  }
}

.mobile-container {
  .error-boundary {
    margin-top: 85px;
    border: 0;
    padding: 1rem;
  }
}