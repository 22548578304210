.step4-search-container {
  .other-date-slots-wrapper {
    margin-left: -3 * $gzn-padding;
    margin-right: -3 * $gzn-padding;
    margin-bottom: -2.5 * $gzn-padding;
    background: $gzn-light-background;
    position: relative;
  }
}
.mobile-container {
  .step4-search-container {
    .other-date-slots-wrapper {
      margin-left: -2 * $gzn-padding;
      margin-right: -2 * $gzn-padding;
      margin-bottom: -2.5 * $gzn-padding;
      background: $gzn-light-background;
      position: relative;
    }
  }
}

.date-slots-container {
  display: inline-block;
  text-align: center;
  margin-right: $gzn-padding;
  width: 13rem;
  vertical-align: top;

  &.wider {
    width: 15rem;
  }

  .date {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .time-list {
    .btn {
      position: relative;
      margin-top: 0.25rem;

      .preferred {
        position: absolute;
        left: 0.5rem;
      }
    }
  }

  .tech-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.step4-search-container {
  .gold-star-explanation {
    font-size: 80%;
    opacity: 0.6;
  }

  .centered-information {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .other-date-slots-shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset -25px 0 25px -25px rgba(0,0,0,0.5);
    pointer-events: none;
  }

  .other-date-slots-mask {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 2*$gzn-padding 3*$gzn-padding;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  .target-date-slots-container {
    margin-bottom: $gzn-padding;

    .date-slots-container {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .other-date-slots-wrapper {
    .pagination-button {
      position: absolute;
      top: 40%;
      border-radius: 100%;
      text-align: center;
      background: lighten($gzn-green, 10%);
      color: #fff;
      font-size: 2rem;
      width: 3rem;
      height: 3rem;
      cursor: pointer;
      z-index: 150;
      box-shadow: 0 0 5px rgba(0,0,0,0.25);
      &:hover {
        background: lighten($gzn-green, 15%);
      }
    }
    .other-date-slots-more-left {
      left: -2rem;
      svg { padding-right: 0.25rem }
    }
    .other-date-slots-more-right {
      right: -2rem;
      svg { padding-left: 0.25rem }
    }
  }
}

.mobile-container {
  .other-date-slots-wrapper {
    .pagination-button {
      display: none;
    }
  }
}
